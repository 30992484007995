import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "upload-container" }
const _hoisted_2 = { class: "upload-file-container" }
const _hoisted_3 = {
  key: 3,
  class: "preview-image"
}
const _hoisted_4 = {
  key: 1,
  class: "image-container"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { axiosAPI } from '@/plugins/axios';
import { updateCoverImageByVideoKey } from '@/services/api/manage';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import TabList from '@/modules/shared/components/molecules/tabList/TabList.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import TabItem from '@/modules/shared/components/atoms/tabItem/TabItem.vue';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { getQueryAsString } from '@/modules/shared/utils/query';
import type { StatusState } from '@/modules/shared/types/state.type';
import type { CoverImage } from '@/modules/shared/types/coverImage.type';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { VideoSnapshot } from '@/modules/videoDetail/types/videoSnapshot';
import { loadVideoScreenShot } from '@/modules/videoDetail/services';

interface ChooseCoverImageModalProps {
  coverImage: VideoSnapshot | CoverImage | string;
  isDisabledSnapshot: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChooseCoverImageModal',
  props: {
    coverImage: {},
    isDisabledSnapshot: { type: Boolean }
  },
  emits: ["modalClose", "selectedImage"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { isDisabledSnapshot } = toRefs(props);
const { t } = useI18n();

const coverImageData = ref<CoverImage | null>(null);
const percentCompleted = ref<number>(0);
const coverImageUploadState = ref<StatusState>(setStateSavingStatusByType());
const file = ref<HTMLInputElement | null>(null);
const previewUrl = ref<string | null>(null);
const currentTab = ref('upload');
const errorLoadingImage = ref(null);
const snapshots = ref<VideoSnapshot[]>([]);

const emit = __emit;

const route = useRoute();
const videoKey = computed(() => getQueryAsString(route.params, 'videoKey'));

async function getVideoScreenShot() {
  if (!videoKey.value) {
    return;
  }

  try {
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await loadVideoScreenShot(videoKey.value);
    snapshots.value = response.data;
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    coverImageUploadState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Video Screenshot'),
    });
  }
}

function getErrorLoadingImageCode() {
  return _.get(errorLoadingImage.value, 'status', '');
}

async function destroyCoverImageData() {
  file.value = null;
  previewUrl.value = null;
  coverImageData.value = null;
}

function onCloseModal() {
  if (coverImageUploadState.value.status !== 'loading') {
    emit('modalClose');
  }
}

async function onSelectedImage(image: VideoSnapshot | CoverImage) {
  emit('selectedImage', image);

  if (videoKey.value) {
    const payload = {
      sizes: image.sizes,
      mediaServerType: image.mediaServerType,
    };
    await updateCoverImageByVideoKey(videoKey.value, payload);
  }
  onCloseModal();
}

async function uploadFile() {
  if (!file.value?.files) {
    return;
  }

  previewUrl.value = null;
  coverImageData.value = null;

  const formData = new FormData();
  formData.append('file', file.value.files[0]);

  try {
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'loading' });
    const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;
    const response = await axiosAPI.post<CoverImage>('/image/upload', formData,
      {
        baseURL,
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          percentCompleted.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      });

    coverImageData.value = response.data;
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'success' });
    onSelectedImage(response.data);
  } catch (error) {
    coverImageUploadState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Cover Image'),
    });
  }

}

function fileBrowsed() {
  if (!file.value?.files) {
    return;
  }

  const thisFile = file.value?.files[0];
  previewUrl.value = URL.createObjectURL(thisFile);
}

onMounted(() => {
  if (videoKey.value) {
    getVideoScreenShot();
  } else {
    const timer = setTimeout(() => {
      file.value?.click();
      clearTimeout(timer);
    }, 200);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    size: "large",
    title: _unref(t)('common:coverImage.choose'),
    primaryButtonText: coverImageUploadState.value.status === 'loading' ? `${_unref(t)('common:uploading')} ${percentCompleted.value} %` : _unref(t)('common:button.upload'),
    isDisabledPrimaryButton: coverImageUploadState.value.status === 'loading',
    onClickPrimaryButton: uploadFile,
    secondaryButtonText: previewUrl.value ? _unref(t)('common:button.back') : _unref(t)('common:button.cancel'),
    isDisabledSecondaryButton: coverImageUploadState.value.status === 'loading',
    onClickSecondaryButton: previewUrl.value ? destroyCoverImageData : onCloseModal,
    onModalClose: onCloseModal
  }, _createSlots({
    body: _withCtx(() => [
      (!_unref(isDisabledSnapshot))
        ? (_openBlock(), _createBlock(TabList, {
            key: 0,
            class: "tab-container"
          }, {
            default: _withCtx(() => [
              _createVNode(TabItem, {
                isActive: currentTab.value === 'upload',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (currentTab.value = 'upload'))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('common:coverImage.upload')), 1)
                ]),
                _: 1
              }, 8, ["isActive"]),
              _createVNode(TabItem, {
                isActive: currentTab.value === 'snapshot',
                onClick: _cache[1] || (_cache[1] = ($event: any) => (currentTab.value = 'snapshot'))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('common:coverImage.chooseFromVideo')), 1)
                ]),
                _: 1
              }, 8, ["isActive"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          ((coverImageUploadState.value.status === 'error' && file.value))
            ? (_openBlock(), _createBlock(ErrorMessage, {
                key: 0,
                title: _unref(t)('response:uploadingError.title', { item: _unref(t)('glossary:video') }),
                description: _unref(t)('response:retry'),
                statusCode: coverImageUploadState.value.error?.statusCode,
                onClickButton: destroyCoverImageData,
                buttonPrefixIconClassName: "fas fa-rotate",
                buttonTitle: _unref(t)('common:button.retry')
              }, null, 8, ["title", "description", "statusCode", "buttonTitle"]))
            : (!previewUrl.value && coverImageUploadState.value.status === 'loading')
              ? (_openBlock(), _createBlock(MessageBox, {
                  key: 1,
                  iconClassName: "fad fa-spinner-third fa-spin",
                  title: _unref(t)('common:uploading')
                }, null, 8, ["title"]))
              : (!previewUrl.value && coverImageUploadState.value.status !== 'error')
                ? (_openBlock(), _createBlock(MessageBox, {
                    key: 2,
                    iconClassName: "fas fa-image",
                    title: videoKey.value ? _unref(t)('common:coverImage.required.title') : _unref(t)('common:coverImage.clickToChoose'),
                    description: _unref(t)('common:coverImage.chooseFromSystem'),
                    onClickButton: () => file.value?.click(),
                    buttonTitle: _unref(t)('common:coverImage.chooseFromComputer')
                  }, null, 8, ["title", "description", "onClickButton", "buttonTitle"]))
                : _createCommentVNode("", true),
          _createElementVNode("input", {
            onChange: fileBrowsed,
            type: "file",
            ref_key: "file",
            ref: file,
            accept: "image/x-png,image/gif,image/jpeg",
            style: {"display":"none"}
          }, null, 544),
          (previewUrl.value && coverImageUploadState.value.status !== 'error')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", {
                  style: _normalizeStyle({ 'background-image': 'url(' + previewUrl.value + ')' }),
                  class: "background-container"
                }, null, 4),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "action" }, null, -1))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 512), [
        [_vShow, currentTab.value === 'upload']
      ]),
      (currentTab.value === 'snapshot' && videoKey.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(snapshots.value, (image, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                onClick: ($event: any) => (onSelectedImage(image)),
                class: "item-image"
              }, [
                _createElementVNode("img", {
                  class: "img-fluid",
                  src: image.sizes?.small
                }, null, 8, _hoisted_6)
              ], 8, _hoisted_5))
            }), 128)),
            (snapshots.value && snapshots.value.length == 0)
              ? (_openBlock(), _createBlock(MessageBox, {
                  key: 0,
                  iconClassName: "fas fa-xmark",
                  title: _unref(t)('common:coverImage.notSet'),
                  description: _unref(t)('common:checkingFile')
                }, null, 8, ["title", "description"]))
              : _createCommentVNode("", true),
            (errorLoadingImage.value && !snapshots.value)
              ? (_openBlock(), _createBlock(ErrorMessage, {
                  key: 1,
                  title: _unref(t)('response:errorTitle'),
                  description: _unref(t)('common:coverImage.uploadingFailed'),
                  statusCode: getErrorLoadingImageCode(),
                  onClickButton: getVideoScreenShot,
                  buttonTitle: _unref(t)('common:button.retry')
                }, null, 8, ["title", "description", "statusCode", "buttonTitle"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (previewUrl.value)
      ? {
          name: "secondary-btn-prefix",
          fn: _withCtx(() => [
            _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1))
          ]),
          key: "0"
        }
      : undefined,
    (previewUrl.value && coverImageUploadState.value.status !== 'error')
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(Button, {
              type: "button",
              onClick: _withModifiers(onCloseModal, ["stop"]),
              buttonStyle: "text-secondary"
            }, {
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('common:button.cancel')), 1)
              ]),
              _: 1
            })
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["title", "primaryButtonText", "isDisabledPrimaryButton", "secondaryButtonText", "isDisabledSecondaryButton", "onClickSecondaryButton"]))
}
}

})