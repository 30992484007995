<template>
  <div class="image-field-container">
    <ChooseCoverImageModal
      @modalClose="onCloseImageUploaderModal"
      @selectedImage="onSelectedImage"
      :coverImage="image"
      :isDisabledSnapshot="isDisabledSnapshot"
      v-if="isImageUploaderModalShowing" />
    <div class="image-field-header" v-if="!isEditMode">
      <h5 class="name">{{ title ? title : $t('common:coverImage.title') }}</h5>
      <div class="actions" v-if="!disabledChangeImage">
        <Button type="button" buttonClasses="cover-upload-link-button" buttonStyle="text-primary"
                @click.stop="chooseImage">
          <template v-slot:content>
            {{ actionChooseImageText ? actionChooseImageText : $t('common:coverImage.choose') }}
          </template>
        </Button>
      </div>
    </div>
    <div class="image-field-preview">
      <ImageView
        :source="getImageSource()"
        :imageSize="imageViewSize"
        :aspectRatio="aspectRatio"
        :emptyMessageText="emptyMessageText ? emptyMessageText : $t('common:coverImage.notSet')" />
      <label
        class="cover-file-upload"
        @click.stop="chooseImage">
        Choose image
      </label>
    </div>
    <p class="recommended-text" v-if="!isEditMode">
      {{ $t('common:form.coverImage.suitableResolution') }} 1280x720
    </p>
  </div>
</template>

<script>
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import ChooseCoverImageModal from '../chooseCoverImageModal/ChooseCoverImageModal.vue';

export default {
  props: {
    image: {
      type: [Object, String],
      default: () => null,
    },
    defaultImage: {
      type: Object,
      default: () => null,
    },
    aspectRatio: {
      type: String,
      default: '16:9',
    },
    imageType: {
      type: String,
      default: 'generalCoverImage',
    },
    title: {
      type: String,
    },
    emptyMessageText: {
      type: String,
    },
    actionChooseImageText: {
      type: String,
    },
    disabledChangeImage: {
      type: Boolean,
      default: false,
    },
    imageViewSize: {
      type: String,
      default: 'medium',
    },
    isDisabledSnapshot: {
      type: Boolean,
      default: false,
    },
    tabMenu: {
      type: Array,
      default: () => [
        {
          key: 'upload',
          value() {
            return this.$t('common:upload.fromComputer');
          },
        },
        {
          key: 'information',
          value() {
            return this.$t('common:upload.chooseFromList');
          },
        },
      ],
    },
    imageOptions: {
      type: Array,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
    },
  },
  emits: ['update:image'],
  components: {
    ChooseCoverImageModal,
    ImageView,
    Button,
  },
  data() {
    return {
      imageObject: null,
      isImageUploaderModalShowing: false,
    };
  },
  watch: {
    imageObject(newValue) {
      this.$emit('update:image', newValue);
      this.onCloseImageUploaderModal();
    },
  },
  methods: {
    chooseImage() {
      this.onToggleImageUploaderModal();
    },
    onCloseImageUploaderModal() {
      this.isImageUploaderModalShowing = false;
    },
    onToggleImageUploaderModal() {
      this.isImageUploaderModalShowing = !this.isImageUploaderModalShowing;
    },
    getImageSource() {
      if (this.image) {
        return this.image;
      }
      return this.defaultImage;
    },
    onSelectedImage(image) {
      this.imageObject = image;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:deep(.cover-upload-link-button) {
  padding: 0;
}

.image-field-container {
  position: relative;

  .cover-file-upload {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border: none;
    border-radius: 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 20%;
    }
  }
}

.image-field-header {
  display: flex;
  align-items: flex-end;

  .name {
    font-weight: $font-weight-base;
    color: $body-color;
    /* font-family: $font-family-CSPrajad; */
    font-size: $font-size-base;
  }

  .actions {
    margin-left: auto;
    margin-bottom: $spacing-base * 0.35;
    :deep(.button) {
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.image-field-preview {
  position: relative;
}

.recommended-text {
  color: #9ea4a9;
  font-size: 12px;
  margin-top: 8px;
}
</style>
