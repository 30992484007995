<template>
  <router-link
    v-if="url"
    :to="url"
    :class="{active: isActive}"
    class="tab-item">
    <div v-if="$slots['icon']" class="tab-icon">
      <slot name="icon"></slot>
    </div>
    <slot></slot>
    <div
      v-if="!!badgeValue"
      :class="{long: badgeValue >= 10}"
      class="tab-badge">
      <span class="badge-value">{{badgeValue}}</span>
    </div>
  </router-link>
  <div
    v-else
    :class="{active: isActive}"
    @click="(event) => this.$emit('click', event)"
    class="tab-item">
    <div v-if="$slots['icon']" class="tab-icon">
      <slot name="icon"></slot>
    </div>
    <slot></slot>
    <div
      v-if="!!badgeValue"
      :class="{long: badgeValue >= 10}"
      class="tab-badge">
      <span class="badge-value">{{badgeValue}}</span>
    </div>
  </div>
</template>

<script>
import { number } from '@intlify/core-base';

export default {
  props: {
    url: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    badgeValue: {
      type: number,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.tab-icon {
  margin-right: $spacing-8;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.tab-badge {
  min-width: 22px;
  height: 22px;
  border-radius: 100px;
  background: $grey-200;
  color: $grey-600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $spacing-4;

  &.long {
    padding: 4px 8px;
  }
}

.tab-item.active .tab-badge {
  background: $ci-primary;
  color: $white;
}

.badge-value {
  margin-top: 1px;
}

</style>
