import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-area-wrapper" }
const _hoisted_2 = ["id", "disabled", "placeholder", "rows", "maxlength", "value", "data-test"]
const _hoisted_3 = {
  key: 0,
  class: "icon-container"
}

import { Nullable } from '@/modules/shared/types/index.type';
import { ref, toRefs } from 'vue';

interface TextAreaProps {
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  modelValue?: Nullable<string>;
  rows?: number;
  maxLength?: number;
  isShowErrorMessage?: boolean;
  dataTest?: string;
  onBlur?: (event: FocusEvent) => (void | Promise<void>);
  onFocus?: (event: FocusEvent) => (void | Promise<void>);
  onChange?: (event: Event) => (void | Promise<void>);
  onKeyDown?: (event: KeyboardEvent) => (void | Promise<void>);
  onInput?: (event: Event) => (void | Promise<void>);
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextArea',
  props: {
    id: {},
    disabled: { type: Boolean },
    placeholder: {},
    modelValue: {},
    rows: { default: 3 },
    maxLength: {},
    isShowErrorMessage: { type: Boolean, default: false },
    dataTest: {},
    onBlur: {},
    onFocus: {},
    onChange: {},
    onKeyDown: {},
    onInput: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const {
  id,
  disabled,
  placeholder,
  modelValue,
  rows,
  maxLength,
  isShowErrorMessage,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  onInput,
} = toRefs(props);

const emit = __emit;

function onInputLocal(event: Event) {
  if (event.target instanceof HTMLTextAreaElement) {
    emit('update:modelValue', event.target.value);
  }

  if (onInput.value) {
    onInput.value(event);
  }
}

const innerTextarea = ref<HTMLTextAreaElement | null>(null);

__expose({
  innerTextarea,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-area-container", { 'has-error': _unref(isShowErrorMessage), disabled: _unref(disabled) }])
    }, [
      _createElementVNode("textarea", {
        ref_key: "innerTextarea",
        ref: innerTextarea,
        class: _normalizeClass(["text-area", { 'has-error-icon': _unref(isShowErrorMessage) }]),
        id: _unref(id),
        disabled: _unref(disabled),
        placeholder: _unref(placeholder),
        rows: _unref(rows),
        maxlength: _unref(maxLength),
        value: _unref(modelValue),
        "data-test": _ctx.dataTest,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onChange) && _unref(onChange)(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(onBlur) && _unref(onBlur)(...args))),
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_unref(onFocus) && _unref(onFocus)(...args))),
        onInput: onInputLocal,
        onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(onKeyDown) && _unref(onKeyDown)(...args)))
      }, null, 42, _hoisted_2)
    ], 2),
    (_unref(isShowErrorMessage) && !_unref(disabled))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fa fa-warning" }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})