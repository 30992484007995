<template>
  <section :id="id" class="section">
    <div
      class="section-header"
      :class="[{'sticky-container': isStickyHeader}, {'add-padding-bottom' : (isUseToggle && !isEnabled)}]"
      :id="isStickyHeader ? 'section-sticky-header' : ''">
      <div class="section-header-container">
        <div class="title-container">
          <slot name="icon" />
          <h4 class="title" v-html="title" v-if="title"></h4>
        </div>
        <div class="toggle-container" v-if="isUseToggle">
          <Toggle :modelValue="isEnabled" @update:modelValue="$emit('clickToggle', !isEnabled)" :labels="toggleLabels" isPrefixLabels/>
        </div>
        <slot name="action"></slot>
      </div>
      <template v-if="$slots['description']">
        <div class="description">
          <slot name="description" />
        </div>
      </template>
      <template v-else>
        <div class="description" v-html="description" v-if="description"></div>
      </template>
    </div>
    <div class="section-body"
         v-if="!isUseToggle || (isUseToggle && isEnabled)"
         :class="{ 'no-padding-top' : !title && !$slots['action'] && !isUseToggle }"
    >
      <slot />
      <div class="section-item-wrapper" v-if="$slots['section-item-container'] || $slots['section-navigator']">
        <div class="section-item-container" v-if="$slots['section-item-container']">
          <slot name="section-item-container" />
        </div>
        <div v-if="$slots['section-navigator']" class="section-navigator">
          <slot name="section-navigator" />
        </div>
      </div>
    </div>
    <div class="section-footer" v-if="$slots['section-footer']">
      <slot name="section-footer"></slot>
    </div>
    <div class="section-footer-with-border" v-if="$slots['section-footer-with-border']">
      <slot name="section-footer-with-border"></slot>
    </div>
  </section>
</template>

<script>
import Toggle from '@/modules/shared/components/atoms/toggle/Toggle.vue';

export default {
  components: {
    Toggle,
  },
  emits: ['clickToggle'],
  props: {
    id: {
      type: String,
      required: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    isUseToggle: {
      type: Boolean,
      default: false,
    },
    isEnabled: {
      type: Boolean,
      default: false,
    },
    isStickyHeader: {
      type: Boolean,
      default: false,
    },
    toggleLabels: {
      type: Object,
      default: () => ({
        on: 'Enabled',
        off: 'Disabled',
      }),
    },
  },
  created() {
    const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('stuck', e.intersectionRatio < 1), { rootMargin: '-1px 0px 0px 0px', threshold: [1] });

    const timer = setTimeout(() => {
      const el = document.getElementById('section-sticky-header');
      if (el) {
        observer.observe(el);
      }
      clearTimeout(timer);
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";
@import "~@/assets/scss/breakpoint.scss";

.section {
  background: $white;
  border-radius: 5px;
  margin-bottom: $spacing-base;
}

.sticky-container {
  position: sticky;
  top:0;
  z-index: 5;
  background: $white;

  &.stuck {
    width: 100%;
    padding: $spacing-base $spacing-32;
    background: $white;
    z-index: 5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    // transform: translateX(-$spacing-32);
  }

  @media screen and (max-width: $max-layout-md){
    padding: $spacing-24;
  }
}

.section-header-container {
  display: flex;
  justify-content: space-between;
}

.title-container {
  display: flex;
  align-items: center;
}

.title {
  font-size: $font-level-4;
  font-weight: $font-weight-base;
  color: $grey-800;
  text-transform: capitalize;

  @media screen and (max-width: $max-layout-md) {
    font-size: $font-level-5;
  }
}

:slotted(.icon) {
  margin-right: $spacing-8;
  color: $grey-800;
}

.toggle-container {
  margin-top: $spacing-8;
  margin-left: $spacing-base;

  @media screen and (max-width: $max-layout-md){
    :deep(.toggle-label) {
      display: none;
    }
  }
}

.description {
  font-size: $font-level-7;
  color: $grey-600;
  margin-top: $spacing-8;
}

.section-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.section-header, .section-body, .section-footer, .section-footer-with-border {
  padding: 0 $spacing-32;
}

.section-header {
  padding-top: $spacing-24;

  &.add-padding-bottom {
    padding-bottom: $spacing-32;
  }
}

.section-body {
  padding-top: $spacing-32;
  padding-bottom: $spacing-32;

  &.no-padding-top {
    padding-top: 0;
  }
}

.section-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .section-navigator {
    flex: 0 0 22.5%;
    margin-left: $spacing-base;
    position: sticky;
    top: 100px;

    @media screen and (max-width: $max-layout-md) {
      display: none;
    }
  }
}

.section-footer {
  margin-top: $spacing-base;
  padding-bottom: $spacing-32;
}

.section-footer-with-border {
  border-top: 1px solid $grey-200;
  padding-top: $spacing-base;
  padding-bottom: $spacing-base;
  display: flex;
  justify-content: flex-end;

  :slotted(a) {
    color: $grey-600 !important;
  }
}

.section-item-container {
  width:100%;
  > :deep(*) {
    padding: $spacing-32 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      // border-bottom: 0.5px solid $grey-300;
    }
  }
}

@media screen and (max-width: $max-layout-md){

  .section-header, .section-body, .section-footer, .section-footer-with-border {
    padding-left: $spacing-24;
    padding-right: $spacing-24;
  }

  .section-header {
    padding-top: $spacing-24;
  }

  .section-body {
    padding-bottom: $spacing-24;
  }

  .section-footer {
    padding-bottom: $spacing-24;
  }
}

@media screen and (max-width: $max-layout-sm){
  .section-header, .section-body, .section-footer, .section-footer-with-border {
    padding-left: $spacing-12;
    padding-right: $spacing-12;
  }

  .section-header {
    padding-top: $spacing-base;
  }

  .section-body {
    padding-bottom: $spacing-base;
  }

  .section-footer {
    padding-bottom: $spacing-base;
  }
}
</style>
