<template>
  <multiselect
    :options="options"
    tag-placeholder="Press enter to add"
    placeholder="Search or add labels"
    v-model="value"
    @close="onCloseDropdown"
    @SearchChange="onSearchChange"
    @tag="onAddLabel"
    taggable
    multiple>
    <template v-slot:noOptions>
      <span class="no-suggestion">
        {{$t('customFields.labels.noSuggestion')}}
      </span>
    </template>
  </multiselect>
</template>

<script>
import _ from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { loadCustomFieldLabels } from '../../../services';

export default {
  props: {
    fieldId: {
      type: String,
      required: true,
    },
    modelValue: {
      required: true,
    },
  },
  emits: ['onAddLabel', 'update:modelValue'],
  setup(props, { emit }) {
    const error = ref(null);
    const options = ref([]);
    const searchValue = ref('');

    const loadOptions = async () => {
      try {
        const response = await loadCustomFieldLabels({ customFieldId: props.fieldId });
        const allLabels = _.get(response, 'data', []);
        options.value = allLabels.map((label) => label.name);
      } catch (e) {
        error.value = e;
      }
    };

    const onAddLabel = (newLabel) => {
      options.value.push(newLabel);
      searchValue.value = '';
      emit('onAddLabel', newLabel);
    };

    const onCloseDropdown = () => {
      if (searchValue.value) {
        onAddLabel(searchValue.value);
      }
      searchValue.value = '';
    };

    const onSearchChange = (searchQuery) => {
      searchValue.value = searchQuery;
    };

    onMounted(() => {
      loadOptions();
    });

    return {
      error,
      loadOptions,
      onAddLabel,
      onCloseDropdown,
      onSearchChange,
      options,
      value: computed({
        get: () => props.modelValue,
        set: (value) => emit('update:modelValue', value),
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables";

.multiselect, :deep(.multiselect__tags) {
  min-height: unset;
}

.multiselect--active {
  height: unset;

  :deep(.multiselect__tags) {
    height: unset;
  }
}

:deep(.multiselect__tags-wrap) {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

:deep(.multiselect__tags) {
  border-radius: $border-radius-3;
  padding: 0;
  padding: 8px 12px;
}

:deep(.multiselect__option), :deep(.multiselect__tag), :deep(.multiselect__input) {
  font-size: $font-size-base;
}

:deep(.multiselect__input), :deep(.multiselect__placeholder) {
  font-size: $font-size-base;
  padding: 0;
  margin: 0;
  min-height: unset;
  line-height: unset;
}

:deep(.multiselect__tag) {
  color: #495057;
  background-color: #dee2e6;
  margin: 0;
}

:deep(.multiselect__option--selected.multiselect__option--highlight) {
  background: $danger;
}

:deep(.multiselect__option--selected.multiselect__option--highlight::after) {
  background: $danger;
}

.no-suggestion {
  color: #9EA4A9;
}
</style>
