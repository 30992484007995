import type {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { LoadingState } from '@/modules/shared/types/state.type';
import { RootState } from '@/store/type';
import { getVideoLighthouseDimensions } from '../services/api';
import type { LighthouseIntegrationState, MetadataKey, LighthouseCustomMetadataItem } from '../types';

type LighthouseIntegrationContext = ActionContext<LighthouseIntegrationState, RootState>;

const state: LighthouseIntegrationState = {
  customDimensionData: null,
  mappedCustomDimensionData: {
    d1: null,
    d2: null,
    d3: null,
    d4: null,
    d5: null,
    d6: null,
    d7: null,
    d8: null,
    d9: null,
    d10: null,
  },
  loadCustomDimensionDataState: setStateLoadingStatusByType(),
  saveCustomDimensionDataState: setStateSavingStatusByType(),
};

const getters: GetterTree<LighthouseIntegrationState, RootState> = {
  isCustomDimensionDataSyncing: (state: LighthouseIntegrationState): boolean => state.customDimensionData?.status === 'syncing',
};

const mutations: MutationTree<LighthouseIntegrationState> = {
  setCustomDimensionData(state: LighthouseIntegrationState, data: LighthouseIntegrationState['customDimensionData']) {
    state.customDimensionData = data;
  },
  destroyCustomDimensionData(state: LighthouseIntegrationState) {
    state.customDimensionData = null;
  },
  setLoadCustomDimensionDataState(state: LighthouseIntegrationState, loadingState: LoadingState) {
    state.loadCustomDimensionDataState = setStateLoadingStatusByType(loadingState);
  },
  setSaveCustomDimensionDataState(state: LighthouseIntegrationState, loadingState: LoadingState) {
    state.saveCustomDimensionDataState = setStateSavingStatusByType(loadingState);
  },
  setMappedCustomDimensionData(state: LighthouseIntegrationState, data: LighthouseIntegrationState['mappedCustomDimensionData']) {
    state.mappedCustomDimensionData = data;
  },
  setMappedCustomDimensionDataByKey(state: LighthouseIntegrationState, data: { key: MetadataKey, value: LighthouseCustomMetadataItem['customField'] }) {
    state.mappedCustomDimensionData = {
      ...state.mappedCustomDimensionData,
      [data.key]: data.value,
    };
  },
};

const actions: ActionTree<LighthouseIntegrationState, RootState> = {
  async loadCustomDimensionData({ commit }: LighthouseIntegrationContext) {
    commit('setLoadCustomDimensionDataState', { type: 'loading' });
    try {
      const response = await getVideoLighthouseDimensions();
      const { customMetaData } = response.data;

      commit('setCustomDimensionData', customMetaData);
      commit('setMappedCustomDimensionData', customMetaData.reduce((previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.key]: currentValue.customField,
      }), state.mappedCustomDimensionData));

      commit('setLoadCustomDimensionDataState', { type: 'success' });
    } catch (error) {
      commit('setLoadCustomDimensionDataState', {
        type: 'error',
        error: formatErrorObject(error, 'Lighthouse Integration'),
      });
    }
  },
  /* eslint-disable */
  async saveCustomDimensionData({ commit }: LighthouseIntegrationContext, payload: any) {
    commit('setSaveCustomDimensionDataState', { type: 'saving' });
    try {
      // const response = await updateCustomDimensionData(payload);
      // commit('setCustomDimensionData', response.data.data);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      commit('setSaveCustomDimensionDataState', { type: 'success' });
      setTimeout(() => {
        commit('setSaveCustomDimensionDataState', { type: 'idle' });
      }, 3000);

      /* TODO: REMOVE IS FAKE SYNCING WHEN THE API IS READY */
      commit('setIsSyncing', true);
    } catch (error) {
      commit('setSaveCustomDimensionDataState', {
        type: 'error',
        error: formatErrorObject(error, 'Lighthouse Integration'),
      });
    }
  },
  destroyLighthouseIntegrationState({ commit }: LighthouseIntegrationContext) {
    commit('destroyCustomDimensionData');
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
