import _ from 'lodash';
import { computed } from 'vue';
import type {
  ActionContext, ActionTree, GetterTree, MutationTree,
} from 'vuex';
import type { RootState } from '@/store/type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { LoadingState } from '@/modules/shared/types/state.type';
import {
  loadS3StorageConnection,
  loadVideoSourceList,
  loadImportBatch,
  loadImportBatchEntry,
  updateHttpBatch,
  updateVideoSourceBatch,
  createS3StorageConnection,
  deleteImportBatchEntry,
  retryImportBatchEntry,
  deleteImportedVideos,
  cancelImportBatchEntry,
  cancelImportBatch,
  updateImportBatchHiddenStatus,
} from '@/modules/videoImport/services/importVideo';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type {
  ImportBatchEntry,
  ImportBatchEntryBy,
  LoadBatchEntriesListPayload,
  VideoImportBatchEntryState,
  ImportBatchDetails,
  VideoSourceS3,
  LoadVideoSourceListPayload,
  UpdateVideoSourceBatchEntryPayload,
  UpdateHttpHeaderBatchEntryPayload,
  UpdateImportBatchHiddenStatusPayload,
  RetryImportSelectedVideoBatchEntryPayload,
  ImportBatchEntryList,
  LoadImportBatchPayload,
  CancelImportBatchPayload,
  DeleteImportedVideosPayload,
} from '../types/VideoImportBatchEntry.type';

type VideoImportBatchEntryContext = ActionContext<VideoImportBatchEntryState, RootState>;

const state: VideoImportBatchEntryState = {
  filterByStatus: null,
  batchDetails: null,
  selectedVideoSource: null,
  batchEntriesList: {
    total: 1,
    from: 1,
    to: 2,
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    data: [],
    jobCountCompleted: 0,
    jobCountFailed: 0,
    jobCountAll: 0,
  },
  selectedBatchEntriesList: [],
  videoSourceList: [],

  loadVideoImportBatchEntryState: setStateLoadingStatusByType(),
  loadVideoImportBatchEntryListState: setStateLoadingStatusByType(),
  loadSelectedVideoSourceBatchEntryState: setStateLoadingStatusByType(),
  loadVideoSourceBatchEntryListState: setStateLoadingStatusByType(),
  addVideoSourceBatchEntryState: setStateLoadingStatusByType(),
  updateVideoSourceBatchEntryState: setStateLoadingStatusByType(),
  cancelVideoBatchEntryState: setStateLoadingStatusByType(),
  deleteVideoBatchEntryState: setStateLoadingStatusByType(),
  updateHttpHeaderBatchEntryState: setStateLoadingStatusByType(),
  retryImportSelectedVideoBatchEntryState: setStateLoadingStatusByType(),
  cancelImportBatchState: setStateLoadingStatusByType(),
  deleteImportedVideosState: setStateLoadingStatusByType(),
  updateImportBatchHiddenStatusState: setStateLoadingStatusByType(),
};

const getters: GetterTree<VideoImportBatchEntryState, RootState> = {
  getBatchDetails: (state) => state.batchDetails,
  getNumberOfCancelledVideosImportBatchEntry: (state) => state.batchDetails?.jobCountCancelled || 0,
  getNumberOfQueuedVideosImportBatchEntry: (state) => state.batchDetails?.jobCountQueued || 0,
  getNumberOfFailedVideosImportBatchEntry: (state) => state.batchDetails?.jobFailed || 0,
  getNumberOfCompletedVideosImportBatchEntry: (state) => state.batchDetails?.jobCountCompleted || 0,
  getNumberOfDeletedVideosImportBatchEntry: (state) => state.batchDetails?.jobCountDeleted || 0,
  getNumberOfAllVideosImportBatchEntry: (state) => state.batchDetails?.jobCountTotal || 0,
  getStatusForFilterImportBatchEntry: () => [
    { id: 'Queued', name: 'Queued' },
    { id: 'Importing', name: 'Importing' },
    { id: 'Ready for transcode', name: 'Ready for transcode' },
    { id: 'Transcoding', name: 'Transcoding' },
    { id: 'Partially completed', name: 'Partially completed' },
    { id: 'Completed', name: 'Completed' },
    { id: 'Failed', name: 'Failed' },
  ],
  getIsHaveSomeVideosImportBatchEntryFailed: (state) => (state.batchDetails?.jobFailed || 0) > 0,
};

const mutations: MutationTree<VideoImportBatchEntryState> = {
  setFilterByStatus(
    state: VideoImportBatchEntryState,
    filterByStatus: ImportBatchEntryBy[] | null,
  ) {
    state.filterByStatus = filterByStatus;
  },
  setBatchDetails(state: VideoImportBatchEntryState, batchDetails: ImportBatchDetails) {
    state.batchDetails = batchDetails;
  },
  setBatchEntriesList(state: VideoImportBatchEntryState, batchEntriesList: ImportBatchEntryList) {
    state.batchEntriesList = batchEntriesList;
  },
  setSelectedVideoSource(state: VideoImportBatchEntryState, selectedVideoSource: VideoSourceS3) {
    state.selectedVideoSource = selectedVideoSource;
  },
  setVideoSourceBatchEntriesList(state: VideoImportBatchEntryState, videoSourceList: VideoSourceS3[]) {
    state.videoSourceList = videoSourceList;
  },
  setLoadVideoImportBatchEntryState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.loadVideoImportBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  updateSelectedBatchEntriesList(
    state: VideoImportBatchEntryState,
    selectedBatchEntry: ImportBatchEntry[],
  ) {
    // Toggle by change unselected to selected & selected to unselected
    const selectedBatchEntryId = state.selectedBatchEntriesList.map((i) => i.id);
    const actionSelectedBatchEntryId = selectedBatchEntry.map((i) => i.id);
    const deselectedBatchEntryId = _.intersection(selectedBatchEntryId, actionSelectedBatchEntryId);
    state.selectedBatchEntriesList = _.union(state.selectedBatchEntriesList, selectedBatchEntry)
      .filter((i) => !deselectedBatchEntryId.includes(i.id));
  },
  setLoadVideoImportBatchEntryListState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.loadVideoImportBatchEntryListState = setStateLoadingStatusByType(loadingState);
  },
  setLoadSelectedVideoSourceBatchEntryState(state: VideoImportBatchEntryState, loadingState: LoadingState) {
    state.loadSelectedVideoSourceBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  setLoadVideoSourceBatchEntryListState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.loadVideoSourceBatchEntryListState = setStateLoadingStatusByType(loadingState);
  },
  setAddVideoSourceBatchEntryState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.addVideoSourceBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  setUpdateVideoSourceBatchEntryState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.updateVideoSourceBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  setCancelVideoBatchEntryState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.cancelVideoBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  setDeleteVideoBatchEntryState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.deleteVideoBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  setUpdateHttpHeaderBatchEntryState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.updateHttpHeaderBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  setRetryImportSelectedVideoBatchEntryState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.retryImportSelectedVideoBatchEntryState = setStateLoadingStatusByType(loadingState);
  },
  setCancelImportBatchState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.cancelImportBatchState = setStateLoadingStatusByType(loadingState);
  },
  setDeleteImportedVideosState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.deleteImportedVideosState = setStateLoadingStatusByType(loadingState);
  },
  setUpdateImportBatchHiddenStatusState(
    state: VideoImportBatchEntryState,
    loadingState: LoadingState,
  ) {
    state.updateImportBatchHiddenStatusState = setStateLoadingStatusByType(loadingState);
  },
};

const actions: ActionTree<VideoImportBatchEntryState, RootState> = {
  async updateFilterByStatus({ commit }: VideoImportBatchEntryContext, filterByStatus: ImportBatchEntryBy[] | null) {
    commit('setFilterByStatus', filterByStatus);
  },
  async resetFilterByStatus({ commit }: VideoImportBatchEntryContext) {
    commit('setFilterByStatus', null);
  },

  async loadBatchDetailsAPI({ commit }: VideoImportBatchEntryContext, payload: LoadImportBatchPayload) {
    const res = await loadImportBatch(payload);
    commit('setBatchDetails', res.data);
  },
  async loadBatchDetails({ commit, dispatch }: VideoImportBatchEntryContext, payload: LoadImportBatchPayload) {
    commit('setLoadVideoImportBatchEntryState', { type: 'loading' });
    try {
      await dispatch('loadBatchDetailsAPI', payload);
      commit('setLoadVideoImportBatchEntryState', { type: 'success' });
    } catch (error) {
      const { importBatchId } = payload;
      commit('setLoadVideoImportBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, `Import Batch Id ${importBatchId}`),
      });
    }
  },
  async reloadBatchDetails({ commit, dispatch }: VideoImportBatchEntryContext, payload: LoadImportBatchPayload) {
    try {
      await dispatch('loadBatchDetailsAPI', payload);
    } catch (error) {
      const { importBatchId } = payload;
      commit('setLoadVideoImportBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, `Import Batch Id ${importBatchId}`),
      });
    }
  },
  async resetBatchDetails({ commit }: VideoImportBatchEntryContext) {
    commit('setBatchDetails', null);
    commit('setLoadVideoImportBatchEntryState', { type: 'idle' });
  },

  async loadBatchEntriesListAPI(
    { commit, state }: VideoImportBatchEntryContext,
    payload: LoadBatchEntriesListPayload,
  ) {
    const {
      projectKey, importBatchId, mainStatus, search, page,
    } = payload;
    const filterByStatus = computed<ImportBatchEntryBy[] | null>(() => state.filterByStatus);
    const res = await loadImportBatchEntry({
      projectKey,
      importBatchId,
      statusTab: mainStatus ?? 'all',
      statusTags: filterByStatus.value?.map((i) => _.camelCase(i.status)),
      sourceUrl: search ?? '',
      page: page ?? 1,
      limit: 20,
    });
    commit('setBatchEntriesList', res.data);
  },
  async loadBatchEntriesList(
    { commit, dispatch }: VideoImportBatchEntryContext,
    payload: LoadBatchEntriesListPayload,
  ) {
    commit('setLoadVideoImportBatchEntryListState', { type: 'loading' });
    try {
      await dispatch('loadBatchEntriesListAPI', payload);
      commit('setLoadVideoImportBatchEntryListState', { type: 'success' });
    } catch (error) {
      commit('setLoadVideoImportBatchEntryListState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async reloadBatchEntriesList(
    { commit, dispatch }: VideoImportBatchEntryContext,
    payload: LoadBatchEntriesListPayload,
  ) {
    try {
      await dispatch('loadBatchEntriesListAPI', payload);
    } catch (error) {
      commit('setLoadVideoImportBatchEntryListState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetBatchEntriesList({ commit }: VideoImportBatchEntryContext) {
    commit('setBatchEntriesList', []);
    commit('setLoadVideoImportBatchEntryListState', { type: 'idle' });
  },

  async loadSelectedVideoSourceBatchEntry({ commit }: VideoImportBatchEntryContext, storageId: string) {
    commit('setLoadSelectedVideoSourceBatchEntryState', { type: 'loading' });
    try {
      const res = await loadS3StorageConnection({ storageId });
      commit('setSelectedVideoSource', res.data);
      commit('setLoadSelectedVideoSourceBatchEntryState', { type: 'success' });
    } catch (error) {
      commit('setLoadSelectedVideoSourceBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetSelectedVideoSourceBatchEntry({ commit }: VideoImportBatchEntryContext) {
    commit('setSelectedVideoSource', null);
    commit('setLoadSelectedVideoSourceBatchEntryState', { type: 'idle' });
  },

  async loadVideoSourceBatchEntriesList({ commit }: VideoImportBatchEntryContext, payload: LoadVideoSourceListPayload) {
    commit('setLoadVideoSourceBatchEntryListState', { type: 'loading' });
    try {
      const res = await loadVideoSourceList({ bucketName: payload?.search ?? '' });
      commit('setVideoSourceBatchEntriesList', res.data);
      commit('setLoadVideoSourceBatchEntryListState', { type: 'success' });
    } catch (error) {
      commit('setLoadVideoSourceBatchEntryListState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetVideoSourceBatchEntriesList({ commit }: VideoImportBatchEntryContext) {
    commit('setVideoSourceBatchEntriesList', []);
    commit('setLoadVideoSourceBatchEntryListState', { type: 'idle' });
  },

  async addVideoSourceBatchEntry({ commit }: VideoImportBatchEntryContext, videoSource: VideoSourceS3) {
    commit('setAddVideoSourceBatchEntryState', { type: 'loading' });
    try {
      const res = await createS3StorageConnection({
        name: videoSource.name,
        accessKeyId: videoSource.accessKeyId,
        secretAccessKey: videoSource.secretAccessKey ?? '',
        region: videoSource.region ?? 'Asia',
        endpoint: videoSource.endpoint,
        signatureVersion: videoSource.signatureVersion ?? 'v2',
      });
      commit('setAddVideoSourceBatchEntryState', { type: 'success' });
      return res.data.id;
    } catch (error) {
      commit('setAddVideoSourceBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
      return null;
    }
  },
  async resetAddVideoSourceBatchEntryState({ commit }: VideoImportBatchEntryContext) {
    commit('setAddVideoSourceBatchEntryState', { type: 'idle' });
  },

  async updateVideoSourceBatchEntry({ commit, state }: VideoImportBatchEntryContext, payload: UpdateVideoSourceBatchEntryPayload) {
    commit('setUpdateVideoSourceBatchEntryState', { type: 'loading' });
    try {
      const {
        projectKey, importBatchId, newVideoSource, isRetry,
      } = payload;
      const res = await updateVideoSourceBatch({
        projectKey,
        importBatchId,
        newS3ConnectionId: newVideoSource.id,
        isRetry: isRetry || false,
      });
      commit('setBatchDetails', {
        ...state.batchDetails,
        sourceOptions: {
          type: 's3',
          connectionId: newVideoSource.id,
        },
        createdAt: res.data.createdAt,
        updatedAt: res.data.updatedAt,
      });
      commit('setUpdateVideoSourceBatchEntryState', { type: 'success' });
    } catch (error) {
      commit('setUpdateVideoSourceBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetUpdateVideoSourceBatchEntryState({ commit }: VideoImportBatchEntryContext) {
    commit('setUpdateVideoSourceBatchEntryState', { type: 'idle' });
  },

  async cancelVideoBatchEntry({ commit, state, dispatch }: VideoImportBatchEntryContext, payload: {
    projectKey: string,
    batchItem: ImportBatchEntry | ImportBatchEntry[],
  }) {
    const { projectKey, batchItem } = payload;
    commit('setCancelVideoBatchEntryState', { type: 'loading' });
    try {
      if (Array.isArray(batchItem)) {
        await cancelImportBatchEntry({
          projectKey,
          importBatchId: batchItem[0]?.importBatchId ?? '',
          importBatchEntryIds: batchItem.map((i) => i.id),
        });
      } else {
        await cancelImportBatchEntry({
          projectKey,
          importBatchId: batchItem.importBatchId,
          importBatchEntryIds: [batchItem.id],
        });
      }
      commit('setCancelVideoBatchEntryState', { type: 'success' });
      commit('updateSelectedBatchEntriesList', state.selectedBatchEntriesList);
      await dispatch('resetBatchDetails');
      await dispatch('resetBatchEntriesList');
    } catch (error) {
      commit('setCancelVideoBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetCancelVideoBatchEntryState({ commit }: VideoImportBatchEntryContext) {
    commit('setCancelVideoBatchEntryState', { type: 'idle' });
  },

  async deleteVideoBatchEntry({ commit, dispatch }: VideoImportBatchEntryContext, batchItem: ImportBatchEntry | ImportBatchEntry[]) {
    commit('setDeleteVideoBatchEntryState', { type: 'loading' });
    try {
      if (Array.isArray(batchItem)) {
        await deleteImportBatchEntry({
          importBatchEntryIds: batchItem.map((i) => i.id),
        });
      } else {
        await deleteImportBatchEntry({
          importBatchEntryIds: [batchItem.id],
        });
      }
      commit('setDeleteVideoBatchEntryState', { type: 'success' });
      commit('updateSelectedBatchEntriesList', state.selectedBatchEntriesList);
      await dispatch('resetBatchDetails');
      await dispatch('resetBatchEntriesList');
    } catch (error) {
      commit('setDeleteVideoBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetDeleteVideoBatchEntryState({ commit }: VideoImportBatchEntryContext) {
    commit('setDeleteVideoBatchEntryState', { type: 'idle' });
  },

  async updateHttpHeaderBatchEntry({ commit, state }: VideoImportBatchEntryContext, payload: UpdateHttpHeaderBatchEntryPayload) {
    commit('setUpdateHttpHeaderBatchEntryState', { type: 'loading' });
    try {
      const {
        projectKey, importBatchId, httpHeader, isRetry,
      } = payload;
      const res = await updateHttpBatch({
        projectKey,
        importBatchId,
        newHttp: httpHeader,
        isRetry: isRetry || false,
      });
      commit('setBatchDetails', {
        ...state.batchDetails,
        sourceOptions: {
          type: 'http',
          httpHeaders: httpHeader,
        },
        createdAt: res.data.createdAt,
        updatedAt: res.data.updatedAt,
      });
      commit('setUpdateHttpHeaderBatchEntryState', { type: 'success' });
    } catch (error) {
      commit('setUpdateHttpHeaderBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetUpdateHttpHeaderBatchEntryState({ commit }: VideoImportBatchEntryContext) {
    commit('setUpdateHttpHeaderBatchEntryState', { type: 'idle' });
  },

  async retryImportSelectedVideoBatchEntry({ commit, state, dispatch }: VideoImportBatchEntryContext, payload: RetryImportSelectedVideoBatchEntryPayload) {
    commit('setRetryImportSelectedVideoBatchEntryState', { type: 'loading' });
    try {
      const { projectKey, importBatchId, batchItem } = payload;
      if (Array.isArray(batchItem)) {
        await retryImportBatchEntry({
          projectKey,
          importBatchId,
          importBatchEntryIds: batchItem.map((i) => i.id),
        });
      } else {
        await retryImportBatchEntry({
          projectKey,
          importBatchId,
          importBatchEntryIds: [batchItem.id],
          sourcePath: batchItem.sourceUrl,
        });
      }
      commit('setRetryImportSelectedVideoBatchEntryState', { type: 'success' });
      commit('updateSelectedBatchEntriesList', state.selectedBatchEntriesList);
      await dispatch('resetBatchDetails');
      await dispatch('resetBatchEntriesList');
    } catch (error) {
      commit('setRetryImportSelectedVideoBatchEntryState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetRetryImportSelectedVideoBatchEntryState({ commit }: VideoImportBatchEntryContext) {
    commit('setRetryImportSelectedVideoBatchEntryState', { type: 'idle' });
  },

  async cancelImportBatch({ commit }: VideoImportBatchEntryContext, payload: CancelImportBatchPayload) {
    commit('setCancelImportBatchState', { type: 'loading' });
    try {
      await cancelImportBatch(payload);
      commit('setCancelImportBatchState', { type: 'success' });
    } catch (error) {
      commit('setCancelImportBatchState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetCancelImportBatchState({ commit }: VideoImportBatchEntryContext) {
    commit('setCancelImportBatchState', { type: 'idle' });
  },

  async deleteImportedVideos({ commit }: VideoImportBatchEntryContext, payload: DeleteImportedVideosPayload) {
    commit('setDeleteImportedVideosState', { type: 'loading' });
    try {
      await deleteImportedVideos(payload);
      commit('setDeleteImportedVideosState', { type: 'success' });
    } catch (error) {
      commit('setDeleteImportedVideosState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },

  async updateImportBatchHiddenStatus({ commit }: VideoImportBatchEntryContext, payload: UpdateImportBatchHiddenStatusPayload) {
    commit('setUpdateImportBatchHiddenStatusState', { type: 'loading' });
    try {
      await updateImportBatchHiddenStatus(payload);
      commit('setUpdateImportBatchHiddenStatusState', { type: 'success' });
    } catch (error) {
      commit('setUpdateImportBatchHiddenStatusState', {
        type: 'error',
        error: formatErrorObject(error, 'Import Batch Entry'),
      });
    }
  },
  async resetUpdateImportBatchHiddenStatusState({ commit }: VideoImportBatchEntryContext) {
    commit('setUpdateImportBatchHiddenStatusState', { type: 'idle' });
  },

  async resetVideoImportBatchEntryState({ commit }: VideoImportBatchEntryContext) {
    commit('setFilterByStatus', null);
    commit('setBatchDetails', null);
    commit('setBatchEntriesList', []);
    commit('setVideoSourceList', []);
    commit('setLoadVideoImportBatchEntryState', { type: 'idle' });
    commit('setLoadVideoImportBatchEntryListState', { type: 'idle' });
    commit('setLoadSelectedVideoSourceBatchEntryState', { type: 'idle' });
    commit('setLoadVideoSourceBatchEntryListState', { type: 'idle' });
    commit('setAddVideoSourceBatchEntryState', { type: 'idle' });
    commit('setUpdateVideoSourceBatchEntryState', { type: 'idle' });
    commit('setCancelVideoBatchEntryState', { type: 'idle' });
    commit('setDeleteVideoBatchEntryState', { type: 'idle' });
    commit('setUpdateHttpHeaderBatchEntryState', { type: 'idle' });
    commit('setRetryImportSelectedVideoBatchEntryState', { type: 'idle' });
    commit('setCancelImportBatchState', { type: 'idle' });
    commit('setUpdateImportBatchHiddenStatusState', { type: 'idle' });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
