<template>
  <div class="text-area-wrapper">
    <div
      class="text-area-container"
      :class="{ 'has-error': isShowErrorMessage, disabled: disabled }"
    >
      <textarea
        ref="innerTextarea"
        class="text-area"
        :class="{ 'has-error-icon': isShowErrorMessage }"
        :id="id"
        :disabled="disabled"
        :placeholder="placeholder"
        :rows="rows"
        :maxlength="maxLength"
        :value="modelValue"
        :data-test="dataTest"
        @change="onChange"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInputLocal"
        @keydown="onKeyDown"
      />
    </div>
    <div class="icon-container" v-if="isShowErrorMessage && !disabled">
      <i class="fa fa-warning" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Nullable } from '@/modules/shared/types/index.type';
import { ref, toRefs } from 'vue';

interface TextAreaProps {
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  modelValue?: Nullable<string>;
  rows?: number;
  maxLength?: number;
  isShowErrorMessage?: boolean;
  dataTest?: string;
  onBlur?: (event: FocusEvent) => (void | Promise<void>);
  onFocus?: (event: FocusEvent) => (void | Promise<void>);
  onChange?: (event: Event) => (void | Promise<void>);
  onKeyDown?: (event: KeyboardEvent) => (void | Promise<void>);
  onInput?: (event: Event) => (void | Promise<void>);
}

const props = withDefaults(defineProps<TextAreaProps>(), {
  rows: 3,
  isShowErrorMessage: false,
});

const {
  id,
  disabled,
  placeholder,
  modelValue,
  rows,
  maxLength,
  isShowErrorMessage,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  onInput,
} = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

function onInputLocal(event: Event) {
  if (event.target instanceof HTMLTextAreaElement) {
    emit('update:modelValue', event.target.value);
  }

  if (onInput.value) {
    onInput.value(event);
  }
}

const innerTextarea = ref<HTMLTextAreaElement | null>(null);

defineExpose({
  innerTextarea,
});

</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.text-area-wrapper {
  position: relative;
}

.text-area-container {
  width: 100%;
  position: relative;
  border: 1px solid #e8eaee;
  background: white;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;

  &.disabled {
    background: $grey-100;
    border: 1px solid $grey-200;
  }

  &:not(.disabled, .has-error) {
    &:focus-within {
      border: 1px solid #2e84db;
      box-shadow: 0 0 0 2px #dceaf9;
    }
  }

  &:not(.disabled) {
    &.has-error {
      border: 1px solid $danger;

      &:focus-within {
        box-shadow: 0 0 0 2px #fbdbdb;
      }
    }
  }
}

.text-area {
  outline: none;
  padding: 8px 12px;
  border-radius: 3px;
  width: 100%;
  border: 0;
  background: transparent;
  color: $grey-800;

  &:disabled {
    color: $grey-600;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey-300;
    opacity: 1; /* Firefox */
  }

  &.has-icon {
    width: calc(100% - 32px);
  }
}

.icon-container {
  position: absolute;
  right: 12px;
  top: 8px;
  display: flex;
  align-items: center;
  color: $danger;
}

.error-text {
  display: flex;
  align-items: center;
  color: #eb5757;
  i {
    font-size: 12px;
    margin-right: 4px;
  }
}

.help-text {
  font-size: 12px;
}
</style>
