import store from '@/store';
import type { RouteRecordRaw } from 'vue-router';
import { customField } from '@/modules/customField';
import lighthouseIntegration from '../store/lighthouseIntegration';

export default function createLighthouseIntegrationRoutes(): RouteRecordRaw[] {
  return [{
    name: 'lighthouse',
    path: 'lighthouse',
    component: () => import(/* webpackChunkName: "manageLighthouseIntegration" */ '../pages/ManageLighthouseIntegration.vue'),
    meta: { teamManagement: true },
    beforeEnter: (to, from, next) => {
      if (!store.hasModule('customField')) {
        store.registerModule('customField', customField);
      }

      if (!store.hasModule('lighthouseIntegration')) {
        store.registerModule('lighthouseIntegration', lighthouseIntegration);
      }

      next();
    },
  }];
}
