<template>
  <Modal
    size="large"
    :title="t('common:coverImage.choose')"
    :primaryButtonText="coverImageUploadState.status === 'loading' ? `${t('common:uploading')} ${percentCompleted} %` : t('common:button.upload')"
    :isDisabledPrimaryButton="coverImageUploadState.status === 'loading'"
    :onClickPrimaryButton="uploadFile"
    :secondaryButtonText="previewUrl ? t('common:button.back') : t('common:button.cancel')"
    :isDisabledSecondaryButton="coverImageUploadState.status === 'loading'"
    :onClickSecondaryButton="previewUrl ? destroyCoverImageData : onCloseModal"
    @modalClose="onCloseModal">
    <template v-slot:body>
      <TabList class="tab-container" v-if="!isDisabledSnapshot">
        <TabItem
          :isActive="currentTab === 'upload'"
          @click="currentTab = 'upload'"
        >
          {{ t('common:coverImage.upload') }}
        </TabItem>
        <TabItem
          :isActive="currentTab === 'snapshot'"
          @click="currentTab = 'snapshot'"
        >
          {{ t('common:coverImage.chooseFromVideo') }}
        </TabItem>
      </TabList>
      <div v-show="currentTab === 'upload'" class="upload-container">
        <div class="upload-file-container">
          <ErrorMessage
            v-if="(coverImageUploadState.status === 'error' && file)"
            :title="t('response:uploadingError.title', { item: t('glossary:video') })"
            :description="t('response:retry')"
            :statusCode="coverImageUploadState.error?.statusCode"
            :onClickButton="destroyCoverImageData"
            buttonPrefixIconClassName="fas fa-rotate"
            :buttonTitle="t('common:button.retry')" />
          <MessageBox
            v-else-if="!previewUrl && coverImageUploadState.status === 'loading'"
            iconClassName="fad fa-spinner-third fa-spin"
            :title="t('common:uploading')"
          />
          <MessageBox
            v-else-if="!previewUrl && coverImageUploadState.status !== 'error'"
            iconClassName="fas fa-image"
            :title="videoKey ? t('common:coverImage.required.title') : t('common:coverImage.clickToChoose')"
            :description="t('common:coverImage.chooseFromSystem')"
            :onClickButton="() => file?.click()"
            :buttonTitle="t('common:coverImage.chooseFromComputer')" />
          <input
            @change="fileBrowsed"
            type="file"
            ref="file"
            accept="image/x-png,image/gif,image/jpeg"
            style="display: none">
          <div class="preview-image" v-if="previewUrl && coverImageUploadState.status !== 'error'">
            <div :style="{ 'background-image': 'url(' + previewUrl + ')' }" class="background-container">
            </div>
            <div class="action">
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentTab === 'snapshot' && videoKey" class="image-container">
        <div
          v-for="(image, index) in snapshots"
          :key="index"
          @click="onSelectedImage(image)"
          class="item-image"
        >
          <img class="img-fluid" :src="image.sizes?.small" />
        </div>
        <MessageBox
          v-if="snapshots && snapshots.length == 0"
          iconClassName="fas fa-xmark"
          :title="t('common:coverImage.notSet')"
          :description="t('common:checkingFile')"
        />
        <ErrorMessage
          v-if="errorLoadingImage && !snapshots"
          :title="t('response:errorTitle')"
          :description="t('common:coverImage.uploadingFailed')"
          :statusCode="getErrorLoadingImageCode()"
          :onClickButton="getVideoScreenShot"
          :buttonTitle="t('common:button.retry')" />
      </div>
    </template>
    <template v-slot:secondary-btn-prefix v-if="previewUrl">
      <i class="fas fa-chevron-left" />
    </template>
    <template v-slot:footer v-if="previewUrl && coverImageUploadState.status !== 'error'">
      <Button
        type="button"
        @click.stop="onCloseModal"
        buttonStyle="text-secondary">
        <template v-slot:content>
          {{ t('common:button.cancel') }}
        </template>
      </Button>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { axiosAPI } from '@/plugins/axios';
import { updateCoverImageByVideoKey } from '@/services/api/manage';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import TabList from '@/modules/shared/components/molecules/tabList/TabList.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import TabItem from '@/modules/shared/components/atoms/tabItem/TabItem.vue';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { getQueryAsString } from '@/modules/shared/utils/query';
import type { StatusState } from '@/modules/shared/types/state.type';
import type { CoverImage } from '@/modules/shared/types/coverImage.type';
import {
  setStateLoadingStatusByType,
  setStateSavingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import type { VideoSnapshot } from '@/modules/videoDetail/types/videoSnapshot';
import { loadVideoScreenShot } from '@/modules/videoDetail/services';

interface ChooseCoverImageModalProps {
  coverImage: VideoSnapshot | CoverImage | string;
  isDisabledSnapshot: boolean;
}

const props = defineProps<ChooseCoverImageModalProps>();
const { isDisabledSnapshot } = toRefs(props);
const { t } = useI18n();

const coverImageData = ref<CoverImage | null>(null);
const percentCompleted = ref<number>(0);
const coverImageUploadState = ref<StatusState>(setStateSavingStatusByType());
const file = ref<HTMLInputElement | null>(null);
const previewUrl = ref<string | null>(null);
const currentTab = ref('upload');
const errorLoadingImage = ref(null);
const snapshots = ref<VideoSnapshot[]>([]);

const emit = defineEmits<{
  modalClose: [];
  selectedImage: [value: VideoSnapshot | CoverImage];
}>();

const route = useRoute();
const videoKey = computed(() => getQueryAsString(route.params, 'videoKey'));

async function getVideoScreenShot() {
  if (!videoKey.value) {
    return;
  }

  try {
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'loading' });
    const response = await loadVideoScreenShot(videoKey.value);
    snapshots.value = response.data;
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'success' });
  } catch (error) {
    coverImageUploadState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Video Screenshot'),
    });
  }
}

function getErrorLoadingImageCode() {
  return _.get(errorLoadingImage.value, 'status', '');
}

async function destroyCoverImageData() {
  file.value = null;
  previewUrl.value = null;
  coverImageData.value = null;
}

function onCloseModal() {
  if (coverImageUploadState.value.status !== 'loading') {
    emit('modalClose');
  }
}

async function onSelectedImage(image: VideoSnapshot | CoverImage) {
  emit('selectedImage', image);

  if (videoKey.value) {
    const payload = {
      sizes: image.sizes,
      mediaServerType: image.mediaServerType,
    };
    await updateCoverImageByVideoKey(videoKey.value, payload);
  }
  onCloseModal();
}

async function uploadFile() {
  if (!file.value?.files) {
    return;
  }

  previewUrl.value = null;
  coverImageData.value = null;

  const formData = new FormData();
  formData.append('file', file.value.files[0]);

  try {
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'loading' });
    const baseURL = process.env.VUE_APP_ORIGIN || window.location.origin;
    const response = await axiosAPI.post<CoverImage>('/image/upload', formData,
      {
        baseURL,
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          percentCompleted.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      });

    coverImageData.value = response.data;
    coverImageUploadState.value = setStateLoadingStatusByType({ type: 'success' });
    onSelectedImage(response.data);
  } catch (error) {
    coverImageUploadState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Cover Image'),
    });
  }

}

function fileBrowsed() {
  if (!file.value?.files) {
    return;
  }

  const thisFile = file.value?.files[0];
  previewUrl.value = URL.createObjectURL(thisFile);
}

onMounted(() => {
  if (videoKey.value) {
    getVideoScreenShot();
  } else {
    const timer = setTimeout(() => {
      file.value?.click();
      clearTimeout(timer);
    }, 200);
  }
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

:deep(.retry-button) {
  margin-top: $spacing-base * 1.5;
}

:deep(.btn-upload-from-computer) {
  margin: 0 auto;
  margin-top: $spacing-12;
}

.background-container {
  padding-bottom: 56.25%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  border: 1px solid $grey-200;
  border-radius: 3px;
}

.tab-container {
  margin-top: $spacing-base / 2;
}

.preview-image {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;

    .btn-retry {
      cursor: pointer;
      color: $grey-700;
      margin-right: auto;
    }
  }
}

.image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  align-content: stretch;
  margin: $spacing-base $spacing-base / 2;
  .item-image {
    float: left;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    align-self: center;
    border: 1px solid $grey-200;
    border-radius: 3px;

    img {
      width: 100%;
      object-fit: contain;
    }

    i {
      color: $ci-primary;
      position: absolute;
      right: 0;
      top: 0;
      font-size: $font-level-6;
    }
  }
}

@media screen and (min-width: 769px) {
  .image-container {
    margin: $spacing-base / 2 0;
  }

  .image-container .item-image {
    flex: 0 0 calc(33.33% - (16px / 3));
    margin-right: $spacing-base / 2;
    margin-bottom: $spacing-base / 2;

    &:nth-child(3n + 0) {
      margin-right: 0;
    }
  }

  .upload-file-container {
    padding-top: $spacing-base;
  }

  .action {
    margin-top: $spacing-base;
  }
}

@media screen and (min-width: 481px) and (max-width: $max-layout-md) {
  .image-container {
    margin: $spacing-base / 2 0;
  }

  .image-container .item-image {
    width: calc(100% / 2);
    height: auto;
    padding: $spacing-base / 3;

    &:nth-child(2n + 1) {
      padding-left: 0;
    }

    &:nth-child(2n + 2) {
      padding-right: 0;
    }
  }

  .upload-file-container {
    padding-top: $spacing-base / 2;
  }

  .action {
    margin-top: $spacing-base / 2;
  }
}

@media screen and (min-width: 321px) and (max-width: $max-layout-xs) {
  .image-container {
    margin: $spacing-base / 2 0;
  }

  .image-container .item-image {
    width: calc(100% / 2);
    height: auto;
    padding: $spacing-base / 4;
    &:nth-child(2n + 1) {
      padding-left: 0;
    }

    &:nth-child(2n + 2) {
      padding-right: 0;
    }
  }

  .upload-file-container {
    padding-top: $spacing-base / 3;
  }

  .action {
    margin-top: $spacing-base / 3;
  }
}

@media screen and (max-width: 320px) {
  .image-container {
    margin: $spacing-base / 2 0;
  }

  .image-container .item-image {
    width: calc(100% / 2);
    height: auto;
    padding: $spacing-base / 4;
    &:nth-child(2n + 1) {
      padding-left: 0;
    }

    &:nth-child(2n + 2) {
      padding-right: 0;
    }
  }

  .upload-file-container {
    padding-top: $spacing-base / 4;
  }

  .action {
    margin-top: $spacing-base / 3;
  }
}

.upload-file-container {
  width: 100%;

  transition: all 0.2s ease-out;

  .upload-file {
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    transition: all 0.25s ease-out;

    &:hover {
      border-color: $grey-600;
      background-color: $grey-200;
    }
  }

  .empty-files {
    z-index: 1;

    .title {
      font-size: $font-size-base * 1.5;
      font-weight: $font-weight-base;
    }
  }

  &.dragover {
    opacity: 0.5;
  }
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 200;
  cursor: pointer;
  width: 100%;
}
</style>
